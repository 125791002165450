class ShowCalculator {
    constructor(selector) {
        this.selector = selector;

        this.calculatorButton = this.selector.querySelector('.js-calculator-button');
        this.calculator = this.selector.querySelector('.js-calculator');

        this._showCalculator = this._showCalculator.bind(this);

        this.calculatorButton.addEventListener('click', this._showCalculator);
    }

    _showCalculator(event) {
        event.preventDefault();

        if(this.calculator !== null) {
            this.calculator.style.display = 'grid';
            this.calculator.style.animation = 'slideIn 1s ease forwards';
            this.calculatorButton.style.display = 'none';
        }
    }
}

export default ShowCalculator;