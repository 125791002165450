import PostcodeSearch from "../postcode-search";
import ShowCalculator from "../calculator-frontpage";
import Quiz from "../calculator";

let postcode_search = document.querySelector('.js-postcode-search');
if (postcode_search !== null) {
    new PostcodeSearch(postcode_search);
}

let calculator = document.querySelector('.js-calculator-show');
if (calculator !== null) {
    let quiz = document.querySelector('.js-calculator');
    if (quiz !== null) {
        new Quiz(quiz);
    }
    new ShowCalculator(calculator);
}

