import {get_api_data} from './api';

class PostcodeSearch {
    constructor(selector) {
        this.selector = selector;
        this.form = this.selector.querySelector('[data-element="form"]');
        this.input = this.selector.querySelector('[data-element="input"]');
        this.results = this.selector.querySelector('[data-element="result"]');

        this.results.style.display = 'none';

        this._submit = this._submit.bind(this);

        this.form.addEventListener('submit', this._submit);
    }

    _submit(event) {
        event.preventDefault();

        this._search();
    }

    _search() {
        let value = this.input.value;
        get_api_data('/wp-json/netivo/v1/postcode-search?postcode=' + value).then(data => {
            if (data.result) {
                this.results.innerHTML = '<div class="title title--24">Dowozimy pod Twój adres!</div><p>Mamy nadzieję, że już niedługo pudełka LOVE trafią pod Twoje drzwi.</p>';
                this.results.style.display = 'block';
                this.results.classList.remove('points-map__result--error');
                this.results.classList.add('points-map__result--success');
            } else {
                this.results.innerHTML = '<div class="title title--24">Niestety Twój kod pocztowy jest poza obecnym obszarem dostaw LOVE</div><p>Nasza mapa dostaw wciąż się powiększa, dlatego mamy nadzieję, że niebawem będziemy dowozić pudełka także do Twojej miejscowości.</p>';
                this.results.style.display = 'block';
                this.results.classList.remove('points-map__result--success');
                this.results.classList.add('points-map__result--error');
            }
        }).catch((error) => {
            console.log(error);
            this.results.innerHTML = '<div class="title title--24">Niestety Twój kod pocztowy jest poza obecnym obszarem dostaw LOVE</div><p>Nasza mapa dostaw wciąż się powiększa, dlatego mamy nadzieję, że niebawem będziemy dowozić pudełka także do Twojej miejscowości.</p>';
            this.results.style.display = 'block';
            this.results.classList.remove('points-map__result--success');
            this.results.classList.add('points-map__result--error');
        })
    }
}

export default PostcodeSearch;