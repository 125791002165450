class Quiz {
    constructor(selector) {
        this.selector = selector;

        this.questions = this.selector.querySelectorAll('[data-step]');
        this.answersContainer = this.selector.querySelectorAll('[data-element="answers"]');
        this.answers = this.selector.querySelectorAll('[data-element="answer"]');
        this.nextButton = this.selector.querySelector('[data-element="next"]');
        this.prevButton = this.selector.querySelector('[data-element="prev"]');
        this.nextButtonContent = this.nextButton.innerHTML;

        // this.questionCount = selector.getAttribute('data-count');

        this.currentQuestion = 0;
        this.score = 0;
        this.answersArray = {};

        this._nextQuestion = this._nextQuestion.bind(this);
        this._prevQuestion = this._prevQuestion.bind(this);

        this.answersContainer.forEach(aC => {
            new Answers(aC, this.answersArray);
        })

        this.nextButton.addEventListener('click', this._nextQuestion);
        this.prevButton.addEventListener('click', this._prevQuestion);
    }

    _nextQuestion(event) {
        event.preventDefault();

        console.log(this.answersArray);

        const yOffset = -320;
        const y = this.selector.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});


        if (this._checkAnswer()) {
            if (this.currentQuestion === 0) {
                if (this.answersArray['sex'] !== 'F') {
                    this.currentQuestion = 3;
                } else {
                    this.currentQuestion++;
                }
            } else if (this.currentQuestion === 1) {
                if (this.answersArray['pregnant'] !== 'yes') {
                    this.currentQuestion = 3;
                } else {
                    this.currentQuestion++;
                }
            } else {
                this.currentQuestion++;
            }

            if (this.currentQuestion === this.questions.length) {
                this._showLastStep();
                this.currentQuestion = (this.questions.length - 1);
            } else if (this.answersArray['sex'] === 'F' && this.answersArray['pregnant'] === 'yes' && this.currentQuestion === 5) {
                console.log('before end', this.currentQuestion)
                this._showLastStep();
                this.currentQuestion = (this.questions.length - 1);
            } else {
                this._generateQuestion();
            }
        }
    }

    _prevQuestion(event) {
        event.preventDefault();

        // let resultContainer = this.selector.querySelector('.calculator__results');
        // if (resultContainer !== null) {
        //     resultContainer.remove();
        // }
        //
        // this.nextButton.style.display = 'block';

        if (this.currentQuestion === 3) {
            if (this.answersArray['sex'] === 'M') {
                this.currentQuestion = 0;
            } else {
                if (this.answersArray['pregnant'] === 'yes') {
                    this.currentQuestion--;
                } else {
                    this.currentQuestion = 1;
                }
            }
        } else {
            this.currentQuestion--;
        }

        if (this.currentQuestion < 0) {
            this.currentQuestion = 0;
        }

        this._generateQuestion();
    }

    _generateQuestion() {
        this.questions.forEach(qs => {
            let step = parseInt(qs.getAttribute('data-step'));
            if (this.currentQuestion === step) {
                qs.classList.add('active');
            } else {
                qs.classList.remove('active');
            }
        });

        if (this.currentQuestion === 6) {
            let lose = this.selector.querySelector('[data-value="lose"]');
            let gain = this.selector.querySelector('[data-value="gain"]');

            lose.style.display = 'block';
            gain.style.display = 'block';
            this._calculateScore();
            if (this.score < 1000) {
                console.log('less than 1000')
                lose.style.display = 'none';
            } else if (this.score > 3900) {
                console.log('more than 3900')
                gain.style.display = 'none';
            }
        }

        if (this.currentQuestion === 0) {
            this.prevButton.style.display = 'none';
        } else {
            this.prevButton.style.removeProperty("display");
        }
        if ((this.currentQuestion === (this.questions.length - 1)) || (this.answersArray['sex'] === 'F' && this.answersArray['pregnant'] === 'yes' && this.currentQuestion === 4)) {
            this.nextButton.innerHTML = 'Zobacz diety';
        } else {
            this.nextButton.innerHTML = this.nextButtonContent;
        }
    }

    _checkAnswer() {
        if (Object.keys(this.answersArray).length > 0 && this.currentQuestion === 3) {
            Object.entries(this.answersArray).forEach(([key, value]) => {
                if (value.trim() === '') {
                    delete this.answersArray[key];
                }
            });
        }

        if (this.currentQuestion === 3) {
            let checkedAnswer = [];
            this.questions[this.currentQuestion].querySelectorAll('[data-element="answer"]').forEach(q => {
                let value = q.getAttribute('data-name');
                checkedAnswer.push(value);
                if (isNaN(Number(this.answersArray[value])) || this.answersArray[value].trim() === '') {
                    this._handleError("Wprowadź prawidłową wartość")
                }
            });
            return checkedAnswer.every(key => key in this.answersArray);
        } else {
            let checkedAnswer = this.questions[this.currentQuestion].querySelector('[data-element="answer"]').getAttribute('data-name');
            if (!this.answersArray.hasOwnProperty(checkedAnswer)) {
                this._handleError('Zaznacz odpowiedź');
            }
            return this.answersArray.hasOwnProperty(checkedAnswer);
        }
    }

    _showLastStep() {
        let score = this._calculateScore();


        this.questions.forEach(q => {
            q.classList.remove('active');
        });
        this.prevButton.style.display = 'none';
        this.nextButton.style.display = 'none';

        const resultContainer = document.createElement('div');
        resultContainer.classList.add('calculator__results');
        resultContainer.innerHTML = `<div class="title">Wynik</div>`;
        this.selector.appendChild(resultContainer);
        this._getPosts().then(data => {
            let dietArray = [];
            let resultsArray = [];

            console.log(this.score);
            console.log(score);
            if (score === 0) {
                resultsArray.push({
                    'name': 'Skonsultuj się z dietetykiem',
                    'link': '/kontakt/'
                }); //odtad
            } else if (this.answersArray['sex'] === 'F') {
                if (this.answersArray['pregnant'] === 'yes') {
                    resultsArray = data.mom.pregnant['trimester-' + this.answersArray['trimester']][score];
                } else if (this.answersArray['pregnant'] === 'lactation') {
                    resultsArray = data.mom.lactation[score];
                } else {
                    if (Array.isArray(this.answersArray['diet'])) {
                        this.answersArray['diet'].forEach(diet => {
                            dietArray = data[this.answersArray['goal']][score][diet];
                            resultsArray = resultsArray.concat(dietArray);
                        });
                    } else {
                        resultsArray = data[this.answersArray['goal']][score][this.answersArray['diet']];
                    }
                }
            } else {
                if (Array.isArray(this.answersArray['diet'])) {
                    this.answersArray['diet'].forEach(diet => {
                        dietArray = data[this.answersArray['goal']][score][diet];
                        resultsArray = resultsArray.concat(dietArray);
                    });
                } else {
                    resultsArray = data[this.answersArray['goal']][score][this.answersArray['diet']];
                }
            } //dotad

            if (resultsArray.length > 0) {
                resultsArray.forEach(diet => {
                    const postElement = document.createElement('a');
                    postElement.innerHTML = `${diet.name}`;
                    postElement.classList.add('calculator__result');
                    postElement.setAttribute('href', diet.link);
                    if (!(resultsArray[0]['name'] === 'Skonsultuj się z dietetykiem')) {
                        postElement.setAttribute('target', '_blank');
                    }
                    resultContainer.appendChild(postElement);
                });
            }

        });
    }

    _calculateScore() {
        if (this.answersArray.hasOwnProperty('pregnant')) {
            if (this.answersArray.hasOwnProperty('trimester')) {
                if (this.answersArray['pregnant'] !== 'yes') {
                    delete this.answersArray['trimester'];
                }
            }
            if (this.answersArray['sex'] === 'M') {
                delete this.answersArray['pregnant'];
                delete this.answersArray['trimester'];
            }
        }

        let pal = 1;
        let goal = 0;
        let score = 0;

        if ((this.answersArray['work'] === 'desk' && this.answersArray['activity'] === 'none') || (this.answersArray['work'] === 'mixed' && this.answersArray['activity'] === 'none')) {
            pal = 1.2;
        } else if ((this.answersArray['work'] === 'desk' && this.answersArray['activity'] === 'low') || (this.answersArray['work'] === 'mixed' && this.answersArray['activity'] === 'low') || (this.answersArray['work'] === 'desk' && this.answersArray['activity'] === 'medium')) {
            pal = 1.4;
        } else if ((this.answersArray['work'] === 'mixed' && this.answersArray['activity'] === 'medium') || (this.answersArray['work'] === 'manual' && this.answersArray['activity'] === 'none') || (this.answersArray['work'] === 'desk' && this.answersArray['activity'] === 'high')) {
            pal = 1.6;
        } else if ((this.answersArray['work'] === 'manual' && this.answersArray['activity'] === 'low') || (this.answersArray['work'] === 'manual' && this.answersArray['activity'] === 'medium') || (this.answersArray['work'] === 'desk' && this.answersArray['activity'] === 'very-high') || (this.answersArray['work'] === 'mixed' && this.answersArray['activity'] === 'high') || (this.answersArray['work'] === 'mixed' && this.answersArray['activity'] === 'very-high')) {
            pal = 1.8
        } else if ((this.answersArray['work'] === 'manual' && this.answersArray['activity'] === 'high') || (this.answersArray['work'] === 'manual' && this.answersArray['activity'] === 'very-high')) {
            pal = 2;
        }

        console.log('PAL:', pal);

        // if (this.answersArray['goal'] === 'lose') {
        //     goal = -300;
        // } else if (this.answersArray['goal'] === 'gain') {
        //     goal = 300;
        // }

        console.log('Goal:', goal)
        if (this.answersArray['sex'] === 'F') {

            this.score = ((655.1 + (9.563 * this.answersArray['weight']) + (1.85 * this.answersArray['height']) - (4.676 * this.answersArray['age'])) * pal) + goal;
        } else {
            this.score = ((66.473 + (13.752 * this.answersArray['weight']) + (5.003 * this.answersArray['height']) - (6.775 * this.answersArray['age'])) * pal) + goal;
        }
        console.log(this.score);

        if (this.answersArray['sex'] === 'F' && this.answersArray['pregnant'] === 'yes') {
            if (this.score >= 1400) {
                if (this.score < 1600) {
                    score = 14;
                } else if (this.score < 1900) {
                    score = 16;
                } else if (this.score < 2200) {
                    score = 19;
                } else if (this.score < 2400) {
                    score = 22;
                }
            }
        } else {
            if (this.score > 999) {
                if (this.score < 1200) {
                    score = 10;
                } else if (this.score < 1400) {
                    score = 12;
                } else if (this.score < 1600) {
                    score = 14;
                } else if (this.score < 1900) {
                    score = 16;
                } else if (this.score < 2200) {
                    score = 19;
                } else if (this.score < 2400) {
                    score = 22;
                } else if (this.score < 2700) {
                    score = 24;
                } else if (this.score < 3200) {
                    score = 27;
                } else if (this.score < 3700) {
                    score = 32;
                } else if (this.score <= 3900) {
                    score = 37;
                }
            }
        }

        return score;
    }

    _handleError(error) {
        const modal = this.selector.querySelector('[data-element="error-modal"]');
        const close = this.selector.querySelector('[data-element="error-close"]');
        const message = this.selector.querySelector('[data-element="error-message"]');

        modal.style.display = 'block';
        message.innerHTML = error;

        const closeHandler = () => {
            modal.style.display = 'none';
            document.removeEventListener('click', outsideClickListener);
        };

        close.addEventListener('click', closeHandler, {once: true});

        const outsideClickListener = (event) => {
            if (!modal.contains(event.target)) {
                modal.style.display = 'none';
                document.removeEventListener('click', outsideClickListener);
            }
        };

        setTimeout(() => {
            document.addEventListener('click', outsideClickListener);
        }, 500);
    }

    _getPosts() {
        return fetch('/wp-content/themes/lovecatering/dist/diets.json', {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function (response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

}

class Answers {
    constructor(selector, all_answers) {
        this.selector = selector;
        this.ans = all_answers;
        this.type = this.selector.getAttribute('data-type');

        this._clickAnswer = this._clickAnswer.bind(this);
        this._changeAnswer = this._changeAnswer.bind(this);

        this.answers = this.selector.querySelectorAll('[data-element="answer"]');
        this.answers.forEach(a => {
            if (this.type !== 'text') {
                a.addEventListener('click', this._clickAnswer);
            } else {
                a.addEventListener('change', this._changeAnswer)
            }
        })
    }

    _clickAnswer(event) {
        event.preventDefault();

        let name = event.target.getAttribute('data-name');
        let value = event.target.getAttribute('data-value');

        if (this.type === 'multiple') {

            if (!this.ans.hasOwnProperty(name)) {
                this.ans[name] = [];
            }

            if (event.target.classList.contains('active')) {
                event.target.classList.remove('active');
                let index = this.ans[name].indexOf(value);

                if (index !== -1) {
                    this.ans[name].splice(index, 1);
                }
            } else {
                this.ans[name].push(value);
                event.target.classList.add('active');
            }
        } else {
            this.ans[name] = value;

            this.answers.forEach(a => {
                a.classList.remove('active');
            });
            event.target.classList.add('active');
        }

    }

    _changeAnswer(event) {
        let name = event.target.getAttribute('data-name');

        if (!this.ans.hasOwnProperty(name)) {
            this.ans[name] = [];
        }
        this.ans[name] = event.target.value;
    }
}


export default Quiz;