export const get_api_data = function(url, method = 'GET', params = null) {
    let args = {
        method: method,
        cache: 'no-cache',
        credentials: "same-origin",
        redirect: "follow",
        headers: {
            'Content-Type': 'application/json'
        },
    }
    if(params !== null) {
        args.body = params;
    }
    return fetch(url, args).then(response => {
        if (!response.ok) {
            return response.json().then(function(response) {
                throw Error(response.code);
            })
        } else {
            //console.log(response);
            return response.json();
        }
    });
}
